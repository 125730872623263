var ActionsMixin = {
    methods: {
		add_or_open_fiche: function(horse_id) {
			this.$router.push({ name: 'horseFiche', params: { horse_id }})
		},
		add_or_open_acts: function(horse_id) {
			this.$router.push({ name: 'HorseFicheAllActes', params: { horse_id }})
		}
	}
}

export default ActionsMixin
